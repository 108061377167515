<template>
    <!-- Main wrapper - style you can find in pages.scss -->
    <div :class="sidebarku ? 'show-sidebar' : ''" id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" :data-sidebartype="fullkah"
        data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
        <!-- Topbar header - style you can find in pages.scss -->
        <header class="topbar" data-navbarbg="skin6">
            <nav class="navbar top-navbar navbar-expand-md navbar-light">
                <div class="navbar-header" data-logobg="skin6">
                    <!-- Logo -->
                    <router-link to="/admin" class="navbar-brand">
                        <!-- Logo icon -->
                        <b class="logo-icon">
                            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                            <!-- Dark Logo icon -->
                            <img src="/assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
                            <!-- Light Logo icon -->
                            <img src="/assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />
                        </b>
                        <!--End Logo icon -->
                        <!-- Logo text -->
                        <span class="logo-text">
                            <!-- dark Logo text -->
                            <img src="/assets/images/logo-text.png" alt="homepage" class="dark-logo" />
                            <!-- Light Logo text -->
                            <img src="/assets/images/logo-light-text.png" class="light-logo" alt="homepage" />
                        </span>
                    </router-link>
                    <!-- End Logo -->
                    
                    <!-- This is for the sidebar toggle which is visible on mobile only -->
                    <a @click="SidebarToggle()" class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)"><i
                            class="ti-menu ti-close"></i></a>
                </div>
                <!-- End Logo -->
                
                <div class="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin5">
                    
                    <!-- toggle and nav items -->
                    
                    
                    <!-- Right side toggle and nav items -->
                    
                </div>
            </nav>
        </header>
        
        <!-- End Topbar header -->
        
        
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <aside class="left-sidebar" data-sidebarbg="skin6">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li class="sidebar-item"> <router-link to="/admin" class="sidebar-link waves-effect waves-dark sidebar-link"
                             aria-expanded="false"><i class="mdi mdi-view-dashboard"></i><span
                                    class="hide-menu">Dashboard</span></router-link></li>

                        <li class="sidebar-item"> <router-link to="/admin/soal" class="sidebar-link waves-effect waves-dark sidebar-link"
                                aria-expanded="false"><i
                                    class="mdi mdi-folder"></i><span class="hide-menu">Paket Soal</span></router-link></li>

                        <li class="sidebar-item"> <router-link to="/admin/token" class="sidebar-link waves-effect waves-dark sidebar-link"
                                aria-expanded="false"><i class="mdi mdi-asterisk"></i><span
                                    class="hide-menu">Token</span></router-link></li>
                        <li class="sidebar-item"> <router-link to="/admin/nilai" class="sidebar-link waves-effect waves-dark sidebar-link"
                                aria-expanded="false"><i class="mdi mdi-star"></i><span
                                    class="hide-menu">Nilai Peserta</span></router-link></li>
                        <li class="sidebar-item"> <a @click="Logout()" class="sidebar-link waves-effect waves-dark sidebar-link"
                                aria-expanded="false"><i class="mdi mdi-logout"></i><span
                                    class="hide-menu">Logout</span></a></li>
                        
                        
                    </ul>

                </nav>
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        
        
        <!-- Page wrapper  -->
        <div class="page-wrapper">
            
            <!-- Bread crumb and right sidebar toggle -->
            <div class="page-breadcrumb">
                <div class="row align-items-center">
                    <div class="col-12">
                        <h1 class="mb-0 fw-bold">{{ judul }}</h1> 
                    </div>
                    
                </div>
            </div>
            <!-- End Bread crumb and right sidebar toggle -->
            
            <!-- Container fluid  -->
            <div class="container-fluid">
                <router-view @changeTitle="ChangeT($event)" />
                
                
                
                
            </div>
            
            <!-- End Container fluid  -->
            
            
            <!-- footer -->
            
            <footer class="footer text-center d-none">
                All Rights Reserved by Flexy Admin. Designed and Developed by <a
                    href="https://www.wrappixel.com">WrapPixel</a>.
            </footer>
            
            <!-- End footer -->
            
        </div>
        
        <!-- End Page wrapper  -->
        
    </div>
    
    <!-- End Wrapper -->
    
</template>

<script>
export default {
    name: 'LayoutAdmin',
    // props: ["judul"]
    data() {
        return{
            judul : '',
            sidebarku: false,
            fullkah: 'full',
            layarku: window.screen.orientation.type,
            
        }
    },
    methods:{
        ChangeT(title)
        {
            this.judul=title;
        },
        SidebarToggle()
        {
            this.sidebarku = !this.sidebarku
        },
        Logout(){
            localStorage.removeItem("email");
            localStorage.removeItem("nama");
            localStorage.removeItem("id");
            localStorage.removeItem("role");

            this.$router.push({name: "Login"});
        }
    },
    mounted(){ 

        if(this.layarku === 'portrait-primary'){
            this.fullkah = 'mini-sidebar'
        }

    }
}
</script>

<style scoped>
.form-control{
    border-color: #555;
}
.page-breadcrumb{
    padding-bottom: 0;
}
</style>