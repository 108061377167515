<template>
    <form @submit.prevent="submitin()">
        <div class="form-group">
            <!-- <label>Email</label> -->
            <input type="email" v-model="data.email" class="form-control form-control-lg" placeholder="Email" required />
            <input type="text" v-model="data.nama" class="form-control form-control-lg" placeholder="Nama Lengkap" required />
            <input type="password" v-model="data.password" class="form-control form-control-lg" placeholder="Password" required />
            <input type="password" v-model="data.repassword" class="form-control form-control-lg" placeholder="Ulangi Password" required />
        </div>

        <div v-if="errNotif != ''" class="alert alert-danger">{{errNotif}} </div>

        <button class="btn btn-info btn-lg w-100">Daftar</button>
         <hr/>
        Sudah Memiliki Akun?<br/>
        <router-link to="/" class="btn btn-outline-info btn-lg w-100">Langsung Masuk</router-link>
    </form>
</template>

<script>
import axios from "axios";

export default {
    name:'Login',
    data(){
        return{
            data:{
                email:'',
                nama:'',
                password:'',
                repassword:'',
                role: 'user',
            },
            errNotif:'',
        }
    },
    mounted(){
        this.$emit('changeTitle','Daftar Akun')
    },
    methods:{
        submitin(){
            if(this.data.password === this.data.repassword){
                axios.post('register', this.data)
                .then(res => {
                    localStorage.setItem("id", res.data.data.id);
                    localStorage.setItem("email", res.data.data.email);
                    localStorage.setItem("nama", res.data.data.nama);
                    localStorage.setItem("role", res.data.data.role);
                    // eslint-disable-next-line no-console
                    console.log("res : ",res)
                    this.$router.push("/user")
                })
                .catch(err => {
                this.errNotif = "Pendaftaran gagal"
                // eslint-disable-next-line no-console
                console.log("err : ",err)
            })
                
            }else{
                this.errNotif = "Kedua password tidak cocok"
            }
            
            
        }
    }
}
</script>