<template>
    <div class="row">
        <div class="col-sm-7">
            <cardHalo />
        </div>
        <div class="col-sm">
            <cardHasil />
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div class="card">
                <div class="card-body text-center">
                    <i class="mdi mdi-lightbulb-on card-icon"></i>
                    <h3>KERJAKAN UJIAN</h3>
                </div>
                <button class="card-footer bg-info text-center" @click="toggleModal()">
                    <h2 class="text-white">MULAI</h2>
                </button>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card">
                <div class="card-body text-center">
                    <i class="mdi mdi-star card-icon"></i>
                    <h3>NILAI UJIAN</h3>
                </div>
                <router-link to="/user/nilai" class="card-footer bg-info text-center">
                    <h2 class="text-white">LIHAT</h2>
                </router-link>
            </div>
        </div>
    </div>   

    <div class="modal-dialog fixed-top" :class="modalku ? '':'d-none'">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Mulai Tes</h4>
          <button type="button" class="btn" @click="toggleModal()">&times;</button>
        </div>
        
        <!-- Modal body -->
        <div class="modal-body">
          <label>Masukkan Token</label>
          <input type="text" v-model="token" class="form-control" />

          <div v-if="errToken != ''" class="alert alert-danger">{{errToken}} </div>
        </div>
        
        

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" @click="toggleModal()">Batal</button>
          <button type="button" class="btn btn-info text-white" @click="mulai()" >Mulai</button>
        </div>
        
      </div>
    </div>
</template>

<script>
import axios from "axios";
import cardHasil from '@/components/cardHasil.vue'
import cardHalo from '@/components/cardHalo.vue'

export default {
    name: 'UserDashboard',
    data(){
        return{
            modalku: false,
            token:'UTBK-',
            errToken: '',
        }
    },
    methods:{
        toggleModal()
        {
            this.modalku = !this.modalku
        },
        mulai(){
            axios.get("user/token/"+this.token)
            .then(res => {
                const idnya = res.data.soal;
                
                axios.post("user/hasil",{
                    "email": localStorage.getItem("email"),
                    "token": this.token
                })
                .then(()=>{
                    this.$router.push({
                        name:'UserTes', 
                        params:{
                            jenis:'tps', 
                            id: idnya,
                            token: this.token,
                        }
                    });
                })
                .catch(() => {
                    this.errToken = 'Token sudah pernah kamu gunakan!'
                })

                
            })
            .catch(() => {
                this.errToken = 'Token tidak ditemukan!'
            })
        }
    },
    components: {
        cardHasil,
        cardHalo
    },
}
</script>

<style scoped>
.card-icon{
    color: #68beff;
    font-size: 5em;
}

.border-left{
    border-left: 5px solid;
    
}
</style>