import { createRouter, createWebHistory } from 'vue-router'
import AdminLayout from '../components/layouts/admin.vue'
import LayoutLogin from '../components/layouts/login.vue'
import LayoutUser from '../components/layouts/user.vue'

import AdminDashboard from '../views/admin/Dashboard.vue'

import AdminSoal from '../views/admin/Soal.vue'
import AdminEditSoal from '../views/admin/EditSoal.vue'

import AdminToken from '../views/admin/Token.vue'
import AdminTambahToken from '../views/admin/TambahToken.vue'
import AdminEditToken from '../views/admin/EditToken.vue'

import AdminNilai from '../views/admin/Nilai.vue'
import AdminNilaiToken from '../views/admin/NilaiToken.vue'

// import NotFound from '../views/404.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'

import UserDashboard from '../views/user/Dashboard.vue'
import UserNilai from '../views/user/Nilai.vue'
import UserTes from '../views/user/Tes.vue'

const routes = [
  // {
  //   path: '404',
  //   name: 'NotFound',
  //   component: NotFound
  // },
  // {
  //   path: '*',
  //   redirect: 'NotFound'
  // },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminLayout,
    children: [
      {
        path: '',
        name: 'AdminDashboard',
        component: AdminDashboard
      },
      {
        path: 'soal',
        name : 'AdminSoal',
        component: AdminSoal
      },
      {
        path: 'token',
        name : 'AdminToken',
        component: AdminToken
      },
      {
        path: 'nilai',
        name : 'AdminNilai',
        component: AdminNilai
      },
      {
        path: 'nilai/:token',
        name : 'AdminNilaiToken',
        component: AdminNilaiToken
      },
      {
        path: 'edit-soal/:id/:tes',
        name : 'AdminEditSoal',
        component: AdminEditSoal
      },
      {
        path: 'tambah-token',
        name : 'AdminTambahToken',
        component: AdminTambahToken
      },
      {
        path: 'edit-token/:token',
        name : 'AdminEditToken',
        component: AdminEditToken
      },
    ]
    
  },
  {
    path: '/',
    component: LayoutLogin,
    children: [
      {
        path: '',
        name : 'Login',
        component: Login
      },
      {
        path: 'register',
        name : 'Register',
        component: Register
      },
    ]
  },
  {
    path: '/user',
    component: LayoutUser,
    children: [
      {
        path: '',
        name : 'UserDashboard',
        component: UserDashboard
      },
      {
        path: 'nilai',
        name : 'UserNilai',
        component: UserNilai
      },
      {
        path: 'tes/:jenis',
        name : 'UserTes',
        component: UserTes,
        props: {id:''}
      },
    ]
  },
  
  
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const role = localStorage.getItem("role");
  // const email = localStorage.getItem("email");

  // eslint-disable-next-line no-console
  console.log("role :", role)

  if(!role){
    // eslint-disable-next-line no-console
    console.log("belum login :", to)

    if(to.path == "/register"){
      next()
    }else{
      if(to.path == "/"){
        next();
      }else{
        next("/")
      }
    }
  }else{
    const rolePath = to.path.split("/")

    // eslint-disable-next-line no-console
    console.log("sudah login :", to, "\nrole path :", rolePath)

    if(role == rolePath[1]){
      next();
    }else{
      next("/"+role)
    }
  }
  
});

export default router
