<template>
    <div class="card">
        <div class="card-body">
            <form @submit.prevent="submitin()">
                <div class="form-group">
                    <label>Kode Token</label>
                    <input type="text" class="form-control" v-model="token.token" readonly="true">
                </div>
                <div class="form-group">
                    <label>Instansi</label>
                    <input type="text" class="form-control" placeholder="Nama Instansi" v-model="token.instansi" required>
                </div>
                <div class="form-group">
                    <label>Paket Soal {{ soalPilih }} </label>
                    <select class="form-control" v-model="token.soal" required>
                        <option v-for="s in soal" :key="s.id" :value="s.id" selected="token.soal === s.id" >{{s.nama}} </option>
                    </select>

                </div>
                <div class="form-group">
                    <label>Masa Aktif</label>
                    <input type="date" class="form-control" v-model="token.expired" required>
                    
                </div>
                
                <div v-if="errNotif != ''" class="alert alert-danger">{{errNotif}} </div>

                <button type="submit" class="btn btn-info">Perbarui Token</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'AdminEditToken',
    mounted(){
        this.$emit('changeTitle','Edit Token')

        axios.get("admin/soal")
        .then(res => {
            this.soal = res.data
        })
        .catch()

        const ambil = "admin/token/"+this.$route.params.token
        axios.get(ambil)
        .then(res => {
            this.token = res.data
            // eslint-disable-next-line no-console
            console.log("res :", this.token)
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.error("err token :", err, "\nget : ", ambil)
        })
    },
    data(){
        return{
            token : {},
            soal:{},
            errNotif: ''
        }
    },
    methods: {
        submitin(){
            // alert(JSON.stringify(this.token, null, 1))
            if(confirm("Apakah yakin ingin memperbarui token ini?")){
                axios.patch("admin/token/"+this.$route.params.token, this.token )
                .then(() => {
                    this.$router.push("/admin/token")
                })
                .catch(err => {
                    this.errNotif = `Gagal memperbarui token`
                    // eslint-disable-next-line no-console
                    console.error("err update token :", err)
                })
            }
            
            
        }
    },
    
}
</script>