<template>
    <div class="card">
        <div class="card-body">
          <h4 class="card-title">
            Token Terbaru
          </h4>

          <div class="table-responsive">
                <table class="table table-stripped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Token</th>
                            <th>Instansi</th>
                            <th>Paket Soal</th>
                            <th>Masa Aktif</th>
                        </tr>
                    </thead>
                    <tbody v-if="tokens.length>0">
                        <tr v-for="(token, index) in tokens" :key="index">
                            <td>{{ index+1 }} </td>
                            <td>{{ token.token }} </td>
                            <td>{{token.instansi}} </td>
                            <td>{{this.soal[token.soal]}} </td>
                            <td>{{token.expired}}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="text-center">
                                Belum ada token yang dibuat
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: 'tabelToken',
    mounted(){
        axios.get("admin/soal")
        .then(res => {
            res.data.forEach(r => {
                this.soal[r.id] = r.nama;
                // eslint-disable-next-line no-console
                // console.log("res data :", r)
            });
            // eslint-disable-next-line no-console
            console.log("soal :", this.soal, "\n tipe : ", typeof(this.soal))
            
        })
        .catch()

        axios.get("admin/token")
            .then(res => {
                this.tokens = res.data;
                // eslint-disable-next-line no-console
                // console.log("res :", res)
            })
            .catch()
    },
    data(){
        return{
            soal: [],
            tokens: []
        }
    }
}
</script>