<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <button class="btn btn-info text-white" @click="toggleModal()">
                        <i class="mdi mdi-folder-plus"></i> Paket Soal Baru
                    </button>
                    <div class="table-responsive">
                        <table class="table table-stripped">
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Nama</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="soalku.length>0">
                            <tr v-for="(soal, index) in soalku" :key="index">
                            <td>{{ index+1 }} </td>
                            <td>{{ soal.nama }} </td>
                            <td>
                                <router-link :to="'edit-soal/'+soal.id+'/tps'" class="btn btn-outline-info"><i class="mdi mdi-pencil"></i> Edit TPS</router-link> 
                                <router-link :to="'edit-soal/'+soal.id+'/tpa'" class="btn btn-outline-info"><i class="mdi mdi-pencil"></i> Edit TPA</router-link> 
                                <button class="btn btn-outline-danger" @click="hapus(soal.id, soal.nama)"><i class="mdi mdi-delete"></i> Hapus</button>
                            </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td class="text-center">Belum ada soal yang dibuat</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-dialog fixed-top" :class="modalku ? '':'d-none'">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Paket Soal Baru</h4>
          <button type="button" class="btn" @click="toggleModal()">&times;</button>
        </div>
        
        <!-- Modal body -->
        <div class="modal-body">
          <label>Nama Paket Soal</label>
          <input type="text" v-model="baru" class="form-control" />

          <div v-if="errBuat != ''" class="alert alert-danger">{{errBaru}} </div>
        </div>
        
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" @click="toggleModal()">Close</button>
          <button type="button" class="btn btn-info text-white" @click="soalBaru()">Buat</button>
        </div>
        
      </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name:'AdminSoal',
    data(){
        return{
            soalku: [],
            modalku: false,
            baru: '',
            errBuat: '',
        }
    },
    mounted(){
        this.$emit('changeTitle','Paket Soal ')

        this.ambilSoal();        
    },
    methods:{
        toggleModal()
        {
            this.modalku = !this.modalku
        },
        ambilSoal(){
            axios.get("admin/soal")
            .then(res => {
                this.soalku = res.data
                // eslint-disable-next-line no-console
                // console.log("res :", res)
            })
            .catch()
        },
        soalBaru()
        {
            axios.post("admin/soal", {
                nama: this.baru
            })
            .then(() => {
                this.ambilSoal();
                this.modalku = !this.modalku
                this.errBuat = ''
                this.baru = ''
            })
            .catch(err => {
                this.errBuat = 'Gagal membuat soal baru : ('+err+')'
            });
        },
        hapus(id, nama){
            if(confirm("Apakah yakin ingin menghapus paket soal "+nama)){
                axios.delete("admin/soal/"+id)
                .then(() => {
                    this.ambilSoal();
                })
                .catch(err => {
                    this.errBuat = 'Gagal menghapus paket soal : ('+err+')'
                })
            }
        },
    }
}
</script>

<style scoped>
.btn{
    margin: .25em;
    /* color: #fff; */
}
.btn:hover{
    color:#fff;
}

</style>