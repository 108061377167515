<template>
    <div class="card">
        <div class="card-body">
            <h4 class="mb-3">
                <router-link to="/admin/nilai">
                    <i class="mdi mdi-arrow-left"></i> Lihat Nilai Lainnya
                </router-link>
            </h4>

            <div class="tabel-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <!-- <th>Nama</th> -->
                            <th>Email</th>
                            <th>Token</th>
                            <th>TPS</th>
                            <th>TPA</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody v-if="peserta.length>0">
                        <tr v-for="(m, index) in peserta" :key="index">
                            <td>{{ index+1 }} </td>
                            <!-- <td style="text-transform: capitalize;">{{ m.nama }} </td> -->
                            <td>{{ m.email }} </td>
                            <td>{{ m.token }}</td>
                            <td>{{ m.nilai_tps }}</td>
                            <td>{{ m.nilai_tpa }}</td>
                            <td>{{ m.nilai_tps + m.nilai_tpa }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="text-center">
                                Sedang mengambil nilai
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'AdminNilaiToken',
    mounted(){
        this.$emit('changeTitle','Nilai Ujian Untuk Token')

        axios.get("admin/hasil/"+this.$route.params.token)
        .then(res => {
            this.peserta = res.data
            // eslint-disable-next-line no-console
            console.log("res data :", res.data)
        })
        .catch()

        
    },
    data(){
        return{
            peserta:[]
        }
    },
}
</script>

<style scoped>
</style>