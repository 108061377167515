<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm border-left border-info mb-2">
                    <h1 class="text-info">{{ jumlah }}X</h1>
                    <h4 class="text-muted">Ujian</h4>
                </div>
                <div class="col-sm border-left border-info mb-2">
                    <h1 class="text-info">{{ top }}</h1>
                    <h4 class="text-muted">Nilai Tertinggi</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name:'cardHasil',
    data(){
        return{
            jumlah: 0,
            top: 0,
        }
    },
    mounted(){
        axios.get("user/hasil/"+localStorage.getItem("email"))
        .then(res => {

            if(res.data.length>0){
                let total = [];

                res.data.forEach(e => {
                    total.push(e.nilai_tps + e.nilai_tpa)
                });
                
                this.top = Math.max(...total)
                this.jumlah = res.data.length
            }
            

        })
        .catch()
    }
}
</script>