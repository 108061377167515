<template>
<ol>
    <div class="card">
        <div class="card-body">
            <label>Nama</label>
            <input type="text" class="form-control" v-model="nama" />
        </div>
    </div>
    <div v-if="komponen.length>0">
        <div class="card" v-for="(komp, index) in komponen" :key="index">
            <div class="card-body">
                
                <div class="row">
                    <div class="col-sm-11">

                        <div v-if="komp.jenis == 'soal'">
                            <h4><li>Pertanyaan</li></h4>
                            <div class="form-group">
                                <label>Soal</label>
                                <textarea class="form-control" v-model="komp.soal"></textarea>
                            </div>
                            <div class="form-group">
                                <label>Pilihan</label>
                                <input type="text" class="form-control" placeholder="a" v-model="komp.a" />
                                <input type="text" class="form-control" placeholder="b" v-model="komp.b" />
                                <input type="text" class="form-control" placeholder="c" v-model="komp.c" />
                                <input type="text" class="form-control" placeholder="d" v-model="komp.d" />
                                <input type="text" class="form-control" placeholder="e" v-model="komp.e" />
                            </div>
                            
                            <div class="form-group">
                                <label>Jawaban</label>
                                <input type="text" class="form-control" v-model="komp.jawaban" placeholder="a/b/c/d/e"/>
                            </div>
                            
                        </div>

                    
                        <div v-else-if="komp.jenis == 'petunjuk'">
                            <h4>Petunjuk </h4>
                            <div class="form-group">
                                <label>Isi Petunjuk</label>
                                <textarea class="form-control"  v-model="komp.isi"></textarea>
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-1">
                        <div class="row">
                            <div class="col-sm-12 col-4">
                                <button class="btn"><h5 class="mdi mdi-arrow-up-bold text-hover" @click="keAtas(index)"></h5></button>
                            </div>
                            <div class="col-sm-12 col-4">
                                <button class="btn"><h5 class="mdi mdi-delete text-hover" @click="hapus(index)"></h5></button>
                            </div>
                            <div class="col-sm-12 col-4">
                                <button class="btn"><h5 class="mdi mdi-arrow-down-bold text-hover" @click="keBawah(index)"></h5></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card" v-else>
        <div class="card-body">
            <h4 class="text-center">Belum ada soal yang dibuat</h4>
        </div>
    </div>
    

</ol>
    <div v-if="errSoal != ''" class="alert alert-danger">{{errSoal}} </div>

    <div class="row">
        <div class="col-sm-3">
            <button class="btn btn-outline-info w-100" @click="tambahSoal()">
                <i class="mdi mdi-plus"></i> Tambah Pertanyaan
            </button>
        </div>
        <div class="col-sm-3">
            <button class="btn btn-outline-info w-100" @click="tambahPetunjuk()">
                <i class="mdi mdi-plus"></i> Tambah Petunjuk
            </button>
        </div>
        <div class="col-sm-3">
            <button class="btn btn-outline-danger w-100" @click="batal()">
                <i class="mdi mdi-close"></i> Batal Edit
            </button>
        </div>
        <div class="col-sm-3">
            <button class="btn btn-info w-100" @click="submitin()">
                <i class="mdi mdi-plus"></i> Simpan Soal
            </button>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'AdminEditSoal',
    data(){
        return{
            komponen:[],
            nama: '',
            errSoal: ''
        }
    },
    mounted(){
        axios.get("admin/soal/"+this.$route.params.id+"/"+this.$route.params.tes)
        .then(res => {
            this.nama = res.data.nama;
            this.komponen = JSON.parse(res.data.soal);
            // eslint-disable-next-line no-console
            console.log("nama : ",this.nama,"\nkomponen : ", this.komponen)
        })
        .catch(err => {
            this.errSoal = err;
        })

        // this.$emit('changeTitle','Edit Soal '+this.$route.params.tes.toUpperCase()+" Paket '" + this.nama + "'")
        this.$emit('changeTitle','Edit Soal ')
    },
    methods : {
        tambahSoal(){
            this.komponen.push(
                {
                    "jenis": "soal",
                    "soal": "",
                    "a": "",
                    "b": "",
                    "c": "",
                    "d": "",
                    "e": "",
                    "jawaban": "",
                }
            )
        },
        tambahPetunjuk(){
            this.komponen.push(
                {
                    "jenis": "petunjuk",
                    "isi": "",
                }
            )
        },
        hapus(index){
            if(confirm("Yakin mau dihapus?")){
                this.komponen.splice(index,1);
            }
        },
        keAtas(index){
            let ini = this.komponen[index];
            let ganti = this.komponen[index-1];

            this.komponen[index] = ganti;
            this.komponen[index-1] = ini;
        },
        keBawah(index){
            let ini = this.komponen[index];
            let ganti = this.komponen[index+1];

            this.komponen[index] = ganti;
            this.komponen[index+1] = ini;
        },
        submitin(){
            if(confirm("Apakah yakin ingin menyimpan soal ini?")){
                axios.patch("admin/soal/"+this.$route.params.id+"/"+this.$route.params.tes,{
                    nama: this.nama,
                    soal: this.komponen
                })
                .then(() => {
                    this.$router.push("/admin/soal")
                })
                .catch(err => {
                    this.errSoal = "Gagal menyimpan soal ("+err+")"
                })
            }
            
        },
        batal(){
            if(confirm("Apakah yakin ingin membatalkan edit soal ini?")){
                this.$router.push("/admin/soal")
            }
        },

    }
}
</script>

<style scoped>
.text-hover{
    color: #888;
    font-size: 1.5em;
}
.text-hover:hover{
    color: #1a9bfc;
}
</style>