<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">
                Paket Soal
            </h4>

            <div class="table-responsive">
                <table class="table table-stripped">
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Nama</th>
                        </tr>
                    </thead>
                    <tbody v-if="soalku.length>0">
                        <tr v-for="(soal, index) in soalku" :key="index">
                        <td>{{ index+1 }} </td>
                        <td>{{ soal.nama }} </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="text-center">Belum ada soal yang dibuat</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'tabelSoal',
    data(){
        return{
            soalku: []
        }
    },
    mounted() {
        axios.get("admin/soal")
        .then(res => {
            this.soalku = res.data
            // eslint-disable-next-line no-console
            // console.log("res :", res)
        })
        .catch()
    }
}
</script>