<template>
    <div class="tengahkan">
        <div class="timer">
            {{ prettyTime }}
        </div>
    </div>
    <center>
        <h2 class="text-uppercase">tes {{ $route.params.jenis }}</h2>
        <!-- <h4 class="text-muted">{{ $route.params.id }}</h4> -->
    </center>
    
    <div class="card w-100">
        <div class="card-body">
            <!-- {{ $route.params.id }} - {{ $route.params.jenis }} -->
            <ol>
            <carousel :items-to-show="1" :model-value="sekarang">
                <slide v-for="(soal, index) in soalku" :key="index">
                    <div class="itemnya" v-if="soal.jenis == 'petunjuk'">
                        <pre>{{ soal.isi }}</pre>
                    </div>

                    <div class="itemnya" v-else-if="soal.jenis == 'soal'">
                        <li><pre>{{soal.soal}}</pre></li>
                        <label class="w-100" :for="index+'a'">
                            <input type="radio" class="d-none" :id="index+'a'" v-model="jawaban[index]" value="a" />
                            <span class="btn btn-outline-info w-100">{{ soal.a }} </span>
                        </label>
                        <label class="w-100" :for="index+'b'">
                            <input type="radio" class="d-none" :id="index+'b'" v-model="jawaban[index]" value="b" />
                            <span class="btn btn-outline-info w-100">{{ soal.b }} </span>
                        </label>
                        <label class="w-100" :for="index+'c'">
                            <input type="radio" class="d-none" :id="index+'c'" v-model="jawaban[index]" value="c" />
                            <span class="btn btn-outline-info w-100">{{ soal.c }} </span>
                        </label>
                        <label class="w-100" :for="index+'d'">
                            <input type="radio" class="d-none" :id="index+'d'" v-model="jawaban[index]" value="d" />
                            <span class="btn btn-outline-info w-100">{{ soal.d }} </span>
                        </label>
                        <label class="w-100" :for="index+'e'">
                            <input type="radio" class="d-none" :id="index+'e'" v-model="jawaban[index]" value="e" />
                            <span class="btn btn-outline-info w-100">{{ soal.e }} </span>
                        </label>
                        
                    </div>
                </slide>
                

                <template #addons="{  }">
                    
                    <!-- <h1>{{ currentSlide }}</h1> -->
                    <!-- <pagination /> -->
                </template>
            </carousel>
            </ol>
        </div>
    </div>
    
    <div class="row">
        <div class="col-1">
            <button class="btn btn-info" @click="prevSlide()"> Prev </button>
        </div>
        <div class="col-10">
            <!-- {{ sekarang }} -->
            <ol>
            <div class="tengahkan">
                <div v-for="nav in navigasi" :key="nav"  class="navigasi">
                    <button class="btn btn-outline-info" @click="keSlide(nav)"> 
                        <li></li> 
                    </button>
                </div>
            </div>
            </ol>
        </div>
        <div class="col-1">
            <button class="btn btn-info" @click="nextSlide()"> Next </button>
        </div>
    </div>
        
    <div class="text-center" v-if="submitkah == true">
        <button class="btn btn-lg btn-info" @click="submitin()">Kirim Jawaban</button>
    </div>
    
    <div class="modal-dialog fixed-top" :class="modalku ? '':'d-none'">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Tes Selesai</h4>
          <button class="btn" @click="toggleModal()">&times;</button>
        </div>
        
        <!-- Modal body -->
        <div class="modal-body">
          <h5 class="text-muted">Kamu sudah menyelesaikan tes</h5>
          
        </div>
        
        <!-- Modal footer -->
        <div class="modal-footer">
          <button class="btn btn-outline-info btn-lg" @click="toggleModal()">Koreksi Lagi</button>
          <button class="btn btn-info btn-lg text-white" @click="submitin()">Kirim Jawaban</button>
        </div>
        
      </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import axios from "axios";

export default {
    name: 'UserTest',
    mounted(){
        this.ambilSoal(this.$route.params.jenis);
        this.start()
    },
    data(){
        return{
            soalku:[
                {
                    "jenis": "petunjuk",
                    "isi": "Disini isi dari petunjuknya.\nInibaris kedua",
                },
                {
                    "jenis": "soal",
                    "soal": "Disini isi dari soalnya.\nInibaris kedua",
                    "a": "1",
                    "b": "2",
                    "c": "3",
                    "d": "4",
                    "e": "5",
                    "jawaban": "",
                }
            ],
            sekarang: 0,
            submitkah: false,
            modalku: false,
            jawaban: {},
            isRunning: false,
            hours: 0,
            minutes:0,
            secondes:0,
            time:6300,
            timer:null,
        }
    },
    methods : {
        nextSlide(){
            // this.sekarang < (this.soalku.length-1) ? this.sekarang++ : this.sekarang;
            if(this.sekarang < (this.soalku.length-1)){
                this.sekarang++
            }else{
                this.submitkah = true;
                this.modalku = true;
            }
        },
        prevSlide(){
            // this.sekarang != 0 ? this.sekarang-- : this.sekarang;
            if(this.sekarang != 0){
                this.sekarang--;
                this.submitkah=false
            }
        },
        keSlide(index){
            this.sekarang = index;
        },
        toggleModal()
        {
            this.modalku = !this.modalku
        },
        ambilSoal(tes){
            axios.get("user/soal/"+this.$route.params.id+"/"+tes)
        .then(res => {
            this.soalku = JSON.parse(res.data.soal);
        })
        .catch(()=>{
            this.$router.push("/user");
        })
        },
        submitin(){
            // alert(this.jawaban)
            // alert(JSON.stringify(this.jawaban))
            // const email = localStorage.getItem("email");
            
            axios.patch("user/hasil/"+localStorage.getItem("email")+"/"+this.$route.params.token,{
                tes: this.$route.params.jenis,
                jawaban: this.jawaban,
                soal: this.$route.params.id,
            })
            .then(() => {
                if(this.$route.params.jenis == "tps" ){
                    this.modalku = false;
                    this.$router.push({
                        name:'UserTes', 
                        params: {
                            jenis:'tpa', 
                            id: this.$route.params.id,
                            token: this.$route.params.token
                            }
                        })
                        this.ambilSoal("tpa");
                        this.sekarang = 0
                        this.jawaban = {}
                        this.reset()
                }else{
                    this.$router.push("/user")
                }
            })
            .catch(err => {
                alert("Gagal mengirim jawaban. \nCoba cek kembali koneksi anda dan coba kirm ulang");
                alert(err);
            })

            
        },
        start() {
            this.isRunning = true
			if (!this.timer) {
                this.timer = setInterval( () => {
                    if(this.time > 0){
                        this.time--;
                    }else{
                        clearInterval(this.timer)
                        alert("selesai");
                        this.reset()
                    }
				}, 1000 )
			}
		},
        stop () {
			this.isRunning = false
			clearInterval(this.timer)
			this.timer = null
		},
		reset() {
			this.stop()
			this.time = 5400
			this.secondes = 0
			this.minutes = 0
		},
    },
    components: {
        Carousel,
        Slide,
        // Pagination,
        // Navigation,
    },
    computed: {
        navigasi: function(){
            let r = [];
            const soal = this.soalku

            for(let s=0; s<(soal.length); s++) {
                if(soal[s].jenis == 'soal'){
                    r.push(s)
                }
            }
            return r
        },
        prettyTime () {
			// let time = (this.time / 60)
            let hours = parseInt(this.time/3600)
			let minutes = parseInt(this.time / 60)%(60*hours)
			let secondes = this.time%(3600*hours+60*minutes)
			return hours+":"+minutes+":"+secondes
		}
    }
}
</script>

<style scoped>
.tengahkan{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    flex-wrap: wrap;
}

.timer{
    width: max-content;
    color: #fff;
    font-weight: bold;
    background-color: #1a9bfc;
    padding: .75em;
    border-radius: .75em;
    font-size: 1.5em;
    margin-bottom: 1em;
    box-shadow: 0 0.5rem 1rem rgba(26, 155, 252, .5) !important;
}

.itemnya{
    text-align: left;
}

.navigasi{
    width: 10%;
    text-align: center;
}
.navigasi li{
    margin-left: 8px;
}

pre{
    font-family: var(--bs-font-sans-serif);
    font-size: 1em;
}

input:checked+.btn{
    background-color: #1a9bfc;
    color: #fff;
}
</style>