<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Pilih token yang nilainya ingin dilihat</h4>
            <div class="row" v-if="tokens.length>0">
                <div class="col-sm-3" v-for="token in tokens" :key="token.DISTINCT">
                    <div class="card">
                        <div class="card-body text-center">
                            <h5 class="card-title">
                                {{ token.DISTINCT }}
                            </h5>
                        </div>
                        <router-link :to="'/admin/nilai/'+token.DISTINCT" class="card-footer bg-info text-center">
                            <h5 class="text-white">LIHAT</h5>
                        </router-link>
                    </div>
                    
                </div>
            </div>
            <div class="row" v-else>
                <div class="col">
                    <div class="card">
                        <div class="cardbody text-center">
                            <h5 class="card-title">
                                Belum ada nilai yang masuk
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'AdminNilai',
    data(){
        return{
            tokens: []
        }
    },
    mounted(){
        this.$emit('changeTitle','Nilai Ujian')

        axios.get("admin/hasil")
        .then(res => {
            this.tokens = res.data
        })
        .catch()
    }
}
</script>