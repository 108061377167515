<template>
    <div class="card">
        <div class="card-body">
            <router-link to="/admin/tambah-token" class="btn btn-info text-white"><i class="mdi mdi-plus"></i> Buat Token Baru</router-link>
            <div v-if="errNotif != ''" class="alert alert-danger">{{errNotif}} </div>
            <div class="table-responsive">
                <table class="table table-stripped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Token</th>
                            <th>Instansi</th>
                            <th>Paket Soal</th>
                            <th>Masa Aktif</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="tokens.length>0">
                        <tr v-for="(token, index) in tokens" :key="index">
                            <td>{{ index+1 }} </td>
                            <td>{{ token.token }} </td>
                            <td>{{token.instansi}} </td>
                            <td>{{soal[token.soal]}} </td>
                            <td>{{token.expired}}</td>
                            <td>
                                <router-link :to="'edit-token/'+token.token" class="btn btn-outline-info">
                                    <i class="mdi mdi-pencil"></i> Edit Token
                                </router-link>
                                <button class="btn btn-outline-danger" @click="hapus(token.token)">
                                    <i class="mdi mdi-delete"></i> Hapus Token
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="text-center">
                                Belum ada token yang dibuat
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'AdminToken',
    mounted(){
        this.$emit('changeTitle','Token Ujian ')

        axios.get("admin/soal")
        .then(res => {
            res.data.forEach(r => {
                this.soal[r.id] = r.nama;
                // eslint-disable-next-line no-console
                // console.log("res data :", r)
            });
            // eslint-disable-next-line no-console
            console.log("soal :", this.soal, "\n tipe : ", typeof(this.soal))
            
        })
        .catch()

        this.ambilToken()
    },
    data(){
        return{
            tokens: [],
            soal: [],
            errNotif: ''
        }
    },
    methods: {
        ambilToken(){
            axios.get("admin/token")
            .then(res => {
                this.tokens = res.data;
                // eslint-disable-next-line no-console
                // console.log("res :", res)
            })
            .catch()
        },
        hapus(token){
            if(confirm("Apakah yakin ingin menghapus token "+token)){
                axios.delete("admin/token/"+token)
                .then(() => {
                    this.ambilToken();
                    this.errNotif= ''
                })
                .catch(err => {
                    this.errNotif = 'Gagal menghapus token';
                    // eslint-disable-next-line no-console
                    console.error("err hapus token :", err)
                })
            }
        },
    },
}
</script>

<style scoped>
.btn{
    margin: .25em;
}
.btn:hover{
    color:#fff;
}
</style>