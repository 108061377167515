<template>
    <h1>Daftar Nilai Ujian</h1>
    <div class="card">
        <div class="card-body">
            <h4 class="mb-3">
                <router-link to="/user">
                    <i class="mdi mdi-arrow-left"></i> Kembali ke Dashboard
                </router-link>
            </h4>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Token</th>
                            <th>Tanggal</th>
                            <th>TPS</th>
                            <th>TPA</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody v-if="nilaiku.length>0">
                        <tr v-for="(nilai, index) in nilaiku" :key="nilai.id">
                            <td>{{ index+1 }} </td>
                            <td><b class="text-info">{{ nilai.token }}</b></td>
                            <td>{{ nilai.updatedAt }}</td>
                            <td>{{ nilai.nilai_tps }}</td>
                            <td>{{ nilai.nilai_tpa }}</td>
                            <td><b class="text-info">{{ nilai.nilai_tps + nilai.nilai_tpa }}</b></td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td>
                                Belum ada nilai yang tercatat
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name:'UserNilai',
    data(){
        return{
            nilaiku: []
        }
    },
    mounted(){
        axios.get("user/hasil/"+localStorage.getItem("email"))
        .then(res => {

            for(let m = 0; m < res.data.length; m++){
                let date = new Date(res.data[m]["updatedAt"])
                res.data[m]["updatedAt"] = date.toLocaleDateString()
            }

            this.nilaiku = res.data

            console.log("res data",res.data);
        })
        .catch()
    },
}
</script>