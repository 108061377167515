<template>
    <div class="card">
        <div class="card-body">
            <form @submit.prevent="submitin()">
                <div class="form-group">
                    <label>Kode Token</label>
                    <input type="text" class="form-control" v-model="data.token" required>
                    <i class="small">Dapat diubah sesuai kebutuhan</i>
                </div>
                <div class="form-group">
                    <label>Instansi</label>
                    <input type="text" class="form-control" placeholder="Nama Instansi" v-model="data.instansi" required>
                </div>
                <div class="form-group">
                    <label>Paket Soal</label>
                    <select class="form-control"  v-model="data.soal" required>
                        <option v-for="s in soal" :key="s.id" :value="s.id">{{ s.nama }} </option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Masa Aktif</label>
                    <input type="date" class="form-control" v-model="data.expired" required>
                </div>

                <button type="submit" class="btn btn-info">Buat Token</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'AdminTambahToken',
    mounted(){
        this.$emit('changeTitle','Buat Token Baru')

        // let soalku = {};

        axios.get("admin/soal")
        .then(res => {
            // res.data.forEach(r => {
            //     soalku[r.id] = r.nama;
            // });
            // this.soal = soalku;
            this.soal = res.data
            
        })
        .catch()
    },
    data(){
        return{
            soal:{},
            data: {
                "token": "UTBK-"+(1000+Math.floor(Math.random() * 2500)),
                "instansi": "",
                "soal": "",
                "expired": ""
            }
        }
    },
    methods: {
        submitin(){
            if(confirm("Apakah yakin ingin menyimpan token ini?")){
                axios.post("admin/token", this.data)
                .then(() => {
                    this.$router.push("/admin/token")
                })
                .catch()
            }
            
        }
    }
}
</script>