<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-4 text-center">
                    <img src="https://img.icons8.com/color/240/000000/smurf.png" style="width:100%; max-width: 5em" />
                </div>
                <div class="col-sm-8">
                    <h5>Halo,</h5>
                    <h2 class="text-info">{{ nama }} </h2>
                    <h5>Sudah siap mengerjakan kan?!</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cardHalo',
    data(){
        return{
            nama: localStorage.getItem("nama")
        }
    }
}
</script>