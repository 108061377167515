<template>
    <form @submit.prevent="submitin()">
        <div class="form-group">
            <!-- <label>Email</label> -->
            <input type="email" v-model="data.email" class="form-control form-control-lg" placeholder="Email" />
        </div>
        <div class="form-group">
            <!-- <label>Password</label> -->
            <input type="password" v-model="data.password" class="form-control form-control-lg" placeholder="Password" />
        </div>

        <div v-if="errNotif != ''" class="alert alert-danger">{{errNotif}} </div>

        <button class="btn btn-info btn-lg w-100">Masuk</button>
        <hr/>
        Belum Memiliki Akun?<br/>
        <router-link to="/register" class="btn btn-outline-info btn-lg w-100">Daftar Sekarang</router-link>
    </form>
</template>

<script>
import axios from "axios"

export default {
    name:'Login',
    data(){
        return{
            data:{
                email: '',
                password: ''
            },
            errNotif:'',
        }
    },
    mounted(){
        this.$emit('changeTitle','Masuk')
    },
    methods:{
        submitin(){
            axios.post('login', this.data)
            .then(res => {
                localStorage.setItem("id", res.data.data.id);
                localStorage.setItem("email", res.data.data.email);
                localStorage.setItem("nama", res.data.data.nama);
                localStorage.setItem("role", res.data.data.role);
                // eslint-disable-next-line no-console
                console.log("res : ",res)
                this.$router.push("/"+res.data.data.role)
            })
            .catch(err => {
                this.errNotif = "Login gagal. Cek kembali email dan password"
                // eslint-disable-next-line no-console
                console.log("err : ",err)
            })
        }
    }
}
</script>