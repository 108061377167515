<template>
    <nav class="navbar navbar-expand-sm bg-light navbar-light sticky-top">
        <a class="navbar-brand justify-content-center" href="#">
            <img src="/assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />
        </a>

        <ul class="nav navbar-nav navbar-right">
            <li class="nav-item">
                <a class="nav-link" @click="Logout()"><i class="mdi mdi-logout"></i> Logout</a>
            </li>
            
        </ul>
    </nav>
    <div class="container">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'LayoutUser',
    data(){
        return{
            judul : '',
        }
    },
    methods : {
        ChangeT(title)
        {
            this.judul=title;
        },
        Logout(){
            localStorage.removeItem("email");
            localStorage.removeItem("nama");
            localStorage.removeItem("id");
            localStorage.removeItem("role");

            this.$router.push({name: "Login"});
        },
    }
}
</script>

<style scoped>
.navbar{
    margin: 1.5em 5%;
    border-radius: 1em;
    padding: 1em;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15) !important;
    display: flex;
    justify-content: space-between;
}
.navbar-light .navbar-nav .nav-link:hover{
    color: #fc4b6c
}

.container{
    padding: 1em 15%;
    margin-top: 1em;
    /* border: 1px solid #222; */
}

.nav-link{
    cursor: pointer;
}
</style>