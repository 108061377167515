<template>
  
  <div class="row">
      <div class="col-sm-3">
          <cardSoal />
      </div>

      <div class="col-sm-3">
          <cardToken />
      </div>

      <div class="col-sm-3">
          <cardUser />
      </div>
  </div>

  <div class="row">
    <div class="col-sm-8">
      <tabelToken />
    </div>

    <div class="col-sm-4">
      <tabelSoal />
    </div>
  </div>
  
</template>

<script>
// @ is an alias to /src
import cardSoal from '@/components/cardSoal.vue'
import cardToken from '@/components/cardToken.vue'
import cardUser from '@/components/cardUser.vue'
import tabelToken from '@/components/tabelToken.vue'
import tabelSoal from '@/components/tabelSoal.vue'

export default {
  name: 'AdminDashboard',
  components: {
    cardSoal,
    cardToken,
    cardUser,
    tabelToken,
    tabelSoal
  },
  data(){
    return{
      ms: [4,3,2,1,0]
    }
  },
  mounted(){
    this.$emit('changeTitle','Dashboard ')
  }
}
</script>

<style scoped>
.ico-big{
  font-size: 3em;
}
.rounded{
  border-radius: 2em !important;
}
</style>