<template>
    <div class="card card-info">
        <div class="card-body">
            <div class="row">
            <div class="col-4">
                <i class="mdi mdi-folder ico-big text-white"></i>
            </div>
            <div class="col-8">
                <h2 class="text-white">{{ soal }} </h2>
                <h5 class="text-white">Paket Soal</h5>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: 'cardSoal',
    data(){
        return{
            soal:0
        }
    },
    mounted(){
        axios.get("admin/soal")
        .then(res => {
            this.soal = res.data.length;
        })
        .catch()
    },
}
</script>

<style scoped>
.ico-big{
  font-size: 3em;
}
</style>