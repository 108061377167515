<template>
    <div class="loginku">
        <div class="card text-center">
            <!-- <h2 class="card-header bg-info border-info text-white"> {{ judul }} </h2> -->
            <div class="card-body">
                <h2 class="text-info">{{ judul }}</h2>
                <hr/>
                <router-view @changeTitle="ChangeT($event)" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LayoutLogin',
    data(){
        return{
            judul : '',
        }
    },
    methods : {
        ChangeT(title)
        {
            this.judul=title;
        },
    }
}
</script>

<style scoped>
.loginku{
    background-image: url('/assets/images/pattern.png');
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>